import React from 'react'
import SEO from "../../components/seo"
import DashboardLayout from '../../components/DashboardLayout'
import MainDashboard from '../../components/DashboardContent/MainDashboard'

const dashboard = () => {
    return (
        <>
        <SEO title="Dashboard" />
        <DashboardLayout>
            <MainDashboard />
        </DashboardLayout>
            
        </>
    )
}

export default dashboard
